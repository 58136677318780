import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function InfoTime({ time }) {
  return (
    <Box display="flex" sx={{ pr: 1 }}>
      <Typography variant="h1">{moment(time).format('HH:mm')}</Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="h2" sx={{ mt: '25px' }}>{moment(time).format('dddd, DD.MM.YYYY')}</Typography>
    </Box>
  );
}

InfoTime.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
};
