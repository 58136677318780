import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Occupied() {
  return (
    <Box
      display="flex"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '15px solid rgb(205, 12, 31, 75%)',
        color: 'rgb(205, 12, 31, 75%)',
        justifyContent: 'center',
        p: 3,
        animation: 'glow 1.4s infinite alternate',
        '@keyframes glow': {
          from: {
            color: 'rgb(205, 12, 31, 0%)',
            border: '15px solid rgb(205, 12, 31, 0%)',
            boxShadow: '0 0 15px -15px rgb(205, 12, 31, 0%)',
          },
          to: {
            color: 'rgb(205, 12, 31, 75%)',
            border: '15px solid rgb(205, 12, 31, 75%)',
            boxShadow: '0 0 15px 15px rgb(205, 12, 31, 50%)',
          },
        },
      }}
    >
      <Typography variant="h1" fontWeight={900} fontSize="10rem">BESETZT</Typography>
    </Box>
  );
}
