import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Occupied from './components/Occupied';
import Info from './components/Info';
import CalenderMonth from './components/CalenderMonth';
import CalenderWeek from './components/CalenderWeek';

export default function AppLayout({
  calender,
  eventCurrent,
  eventNext,
  eventPrevious,
  events,
  time,
  weeks,
}) {
  return (
    <Container
      disableGutters
      maxWidth="false"
      sx={{
        borderLeft: '10px solid',
        borderRight: '10px solid',
        borderColor: eventCurrent ? 'primary.main' : 'secondary.main',
        backgroundColor: 'background.default',
        color: 'white',
      }}
    >
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={10} sx={{ p: 1 }}>
          {calender === 'month' && (
            <CalenderMonth time={time} eventCurrent={eventCurrent} eventNext={eventNext} events={events} weeks={weeks} />
          )}
          {calender === 'week' && (
            <CalenderWeek time={time} eventCurrent={eventCurrent} eventNext={eventNext} events={events} weeks={weeks} />
          )}
          { eventCurrent && <Occupied /> }
        </Grid>
        <Grid item xs={2}>
          <Info eventCurrent={eventCurrent} eventNext={eventNext} eventPrevious={eventPrevious} time={time} />
        </Grid>
      </Grid>
    </Container>
  );
}

AppLayout.propTypes = {
  calender: PropTypes.string.isRequired,
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({
    start: PropTypes.string,
  }),
  eventPrevious: PropTypes.shape({}),
  events: PropTypes.shape({}).isRequired,
  time: PropTypes.instanceOf(Date).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AppLayout.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
  eventPrevious: undefined,
};
