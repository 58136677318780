import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import LOGOS from '../constants/Logos';
import {
  readOrganisation, readTitle, readStart, readEnd,
} from '../services/readEvent';

// const SX_BOX = { mb: 0.5 };
const getSxPaper = (time, event, eventCurrent, eventNext) => {
  const sx = {
    p: 0,
    color: 'black',
    backgroundColor: 'white',
    textAlign: 'left',
    verticalAlign: 'top',
    borderRadius: '5px',
    width: '11.9vw',
  };
  if (moment(event.end).isBefore(moment(time))) {
    sx.backgroundColor = 'background.eventPrevious';
  }
  if (eventCurrent && eventCurrent.uid === event.uid) {
    sx.border = '3px solid';
    sx.borderColor = 'primary.main';
  } else if (!eventCurrent && eventNext && eventNext.uid === event.uid) {
    sx.border = '3px solid';
    sx.borderColor = 'secondary.main';
  }
  return sx;
};
// const getSxLogo = (organisation) => ({
//   backgroundImage: `url(/icon/${LOGOS[organisation].path})`,
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'contain',
//   height: '32px',
//   width: '32px',
//   mr: 0.5,
// });

const SX_AREA = { height: '100%', width: '100%' };
const SX_ROW_HEADER = { backgroundColor: 'background.default', height: '20px', p: 0 };
const SX_ROW = {
  backgroundColor: 'background.default',
  height: 'unset',
  p: 0,
};
const SX_ROW_HOUR = { backgroundColor: 'background.default', height: 'unset', p: 0 };
const SX_CELL_HEADER = {
  backgroundColor: 'background.default',
  height: '20px',
  p: 0,
  width: '11.9vw',
};
const SX_CELL_EMPTY = { backgroundColor: 'background.default', p: 0 };
const SX_CELL_HOUR = {
  backgroundColor: 'background.default',
  p: 0,
  borderTop: '1px solid grey',
  verticalAlign: 'top',
  width: '3vw',
};
const SX_CELL_DAYTIME = (hasBorderTop) => ({
  p: 0,
  height: '5px',
  borderTop: hasBorderTop ? '1px solid grey' : undefined,
  // backgroundColor: isToday ? 'background.default' : 'background.dayFuture',
});

export default function CalenderWeek({
  eventCurrent,
  eventNext,
  events,
  time,
}) {
  const [hours, setHours] = useState([]);
  const [days, setDays] = useState([]);
  const daystamp = useRef();

  useEffect(() => {
    if (!daystamp.current || moment(daystamp.current).diff(moment(time), 'days') > 0) {
      daystamp.current = time;
      const newHours = [];
      for (let hour = 0; hour < 24; hour += 1) {
        for (let minute = 0; minute < 60; minute += 15) {
          const cells = [];
          for (let day = -1; day < 6; day += 1) {
            cells.push(
              moment(time)
                .add(day, 'day')
                .startOf('day')
                .add(hour, 'hours')
                .add(minute, 'minutes'),
            );
          }
          newHours.push({
            hour: moment(time)
              .startOf('day')
              .add(hour, 'hours')
              .add(minute, 'minutes')
              .format('HH:mm'),
            render: minute === 0,
            cells,
          });
        }
      }
      setHours(newHours);
      setDays([
        moment(time).subtract(1, 'day'),
        moment(time),
        moment(time).add(1, 'day'),
        moment(time).add(2, 'day'),
        moment(time).add(3, 'day'),
        moment(time).add(4, 'day'),
        moment(time).add(5, 'day'),
      ]);
    }
  }, [time]);

  return (
    <TableContainer sx={SX_AREA}>
      <Table sx={SX_AREA}>
        <TableBody>
          <TableRow sx={SX_ROW_HEADER}>
            <TableCell sx={SX_CELL_EMPTY} />
            {days.map((day, index) => (
              <TableCell key={day} sx={SX_CELL_HEADER}>
                <Typography variant="caption" fontWeight={index === 1 ? 900 : undefined}>{day.format('dddd')}</Typography>
              </TableCell>
            ))}
          </TableRow>
          {hours.map(({ hour, render, cells }) => (
            <TableRow key={hour} sx={render ? SX_ROW_HOUR : SX_ROW}>
              { render && (
                <TableCell sx={SX_CELL_HOUR} rowSpan={4}>
                  <Typography variant="caption">
                    {hour}
                  </Typography>
                </TableCell>
              )}
              { cells.map((cell) => {
                const event = Object.values(events).find((item) => cell.isSame(moment(item.start)));
                let rowSpan = 1;
                if (event) {
                  rowSpan = moment(event.end).diff(moment(event.start), 'minutes') / 15;
                }
                return event ? (
                  <TableCell
                    key={cell.format('YYYY-MM-DD HH:mm')}
                    sx={getSxPaper(time, event, eventCurrent, eventNext, rowSpan)}
                    rowSpan={rowSpan}
                  >
                    <Box key={event.uid} sx={{ p: 1 }}>
                      <Box display="flex">
                        <img
                          src={`${process.env.PUBLIC_URL}/icon/${LOGOS[readOrganisation(event)].path}`}
                          alt={readOrganisation(event)}
                          style={{ maxHeight: '32px', maxWidth: '32px' }}
                        />
                        <Typography component="div" variant="caption" fontWeight={900} sx={{ ml: 1 }}>{readTitle(event)}</Typography>
                      </Box>
                      <Typography component="div" variant="caption">{`${readStart(event)} Uhr - ${readEnd(event)} Uhr`}</Typography>
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell key={cell.format('YYYY-MM-DD HH:mm')} sx={SX_CELL_DAYTIME(render, moment().isSame(cell, 'day'))} />
                );
              })}
            </TableRow>
          ))}
          {/* <TableRow key={week.id} sx={SX_ROW}> */}
          {/* { week.days.map((dayOfWeek) => (
              <TableCell key={dayOfWeek.id} sx={getSxCell(dayOfWeek, time)}>
                <Typography variant="caption" fontWeight={900} sx={moment(dayOfWeek.date).isSame(moment(time), 'day') ? SX_DATE_TODAY : SX_DATE}>
                  {dayOfWeek.date.format('DD.MM')}
                </Typography>
                { Object.values(events).filter((event) => moment(dayOfWeek.date).isSame(moment(event.start), 'day')).map((event) => (
                  <CalenderMonthEvent
                    key={event.uid}
                    event={event}
                    eventCurrent={eventCurrent}
                    eventNext={eventNext}
                    time={time}
                  />
                ))}
              </TableCell>
            ))} */}
          {/* </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CalenderWeek.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({}),
  events: PropTypes.shape({}).isRequired,
};

CalenderWeek.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
};
