import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import LOGOS from '../constants/Logos';
import {
  readAttendee, readDate, readDescription, readEnd, readOrganisation, readStart, readTitle,
} from '../services/readEvent';

export default function EventDetail({
  event, label, color,
}) {
  const sxPaper = {
    border: '5px solid',
    borderColor: color,
    p: 0.5,
    mr: 1,
  };

  return (
    <Box key={event.uid} sx={{ mt: 1 }}>
      <Typography fontWeight={900} variant="subtitle1">{label}</Typography>
      <Paper sx={sxPaper}>
        <Typography component="div" variant="subtitle1" fontWeight={900}>{readTitle(event)}</Typography>
        <Box display="flex">
          <Box sx={{ mr: 1 }}>
            <img
              src={`${process.env.PUBLIC_URL}/icon/${LOGOS[readOrganisation(event)].path}`}
              alt={readOrganisation(event)}
              style={{ maxWidth: '64px', maxWeight: '64px' }}
            />
          </Box>
          <Box>
            <Typography component="div" variant="caption">Datum:</Typography>
            <Typography component="div" variant="body1" fontWeight={900}>{readDate(event)}</Typography>
            <Typography component="div" variant="caption" sx={{ mt: 0.5 }}>Uhrzeit:</Typography>
            <Typography component="div" variant="body1" fontWeight={900}>{`${readStart(event)} Uhr - ${readEnd(event)} Uhr`}</Typography>
            <Typography component="div" variant="caption" sx={{ mt: 0.5 }}>Gebucht von:</Typography>
            <Typography component="div" variant="body1" fontWeight={900}>{readAttendee(event)}</Typography>
            <Typography component="div" variant="caption" sx={{ mt: 0.5 }}>Beschreibung:</Typography>
            <Typography component="div" variant="body1" fontWeight={900}>{readDescription(event)}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

EventDetail.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  event: PropTypes.shape({
    uid: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    summary: PropTypes.string,
    description: PropTypes.string,
    attendee: PropTypes.shape({
      params: PropTypes.shape({
        CN: PropTypes.string,
      }),
    }),
  }).isRequired,
};

EventDetail.defaultProps = {
  color: 'primary.main',
};
