import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithLabel({
  hours, minutes, seconds, value, max, color,
}) {
  const getCountdown = () => {
    let countdown = '';
    if (hours > 0) {
      countdown += hours < 10 ? `0${hours}:` : `${hours}:`;
    }
    countdown += minutes < 10 ? `0${minutes}:` : `${minutes}:`;
    countdown += seconds < 10 ? `0${seconds}` : seconds;
    return countdown;
  };

  return (
    <Box sx={{ mt: 'auto', mb: 'auto', position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        value={(value / max) * 100}
        size={120}
        color={color}
        sx={{ mt: 'auto', mb: 'auto' }}
      />
      <Box sx={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography variant="subtitle1" component="div" color={color === 'primary' ? 'primary.main' : 'secondary.dark'} fontWeight={500}>
          { getCountdown()}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  color: PropTypes.string,
};

CircularProgressWithLabel.defaultProps = {
  color: 'primary',
};
