import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import LOGOS from '../constants/Logos';
import {
  readStart, readEnd, readAttendee, readOrganisation, readTitle,
} from '../services/readEvent';

const SX_BOX = { mb: 0.5 };
const getSxPaper = (time, event, eventCurrent, eventNext) => {
  const sx = { p: 0.2, textAlign: 'left' };
  if (moment(event.end).isBefore(moment(time))) {
    sx.backgroundColor = 'background.eventPrevious';
  }
  if (eventCurrent && eventCurrent.uid === event.uid) {
    sx.border = '3px solid';
    sx.borderColor = 'primary.main';
  } else if (!eventCurrent && eventNext && eventNext.uid === event.uid) {
    sx.border = '3px solid';
    sx.borderColor = 'secondary.main';
  }
  return sx;
};
const getSxLogo = (organisation) => ({
  backgroundImage: `url(/icon/${LOGOS[organisation].path})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '32px',
  width: '32px',
  mr: 0.5,
});

export default function CalenderMonthEvent({
  event,
  eventCurrent,
  eventNext,
  time,
}) {
  return (
    <Box sx={SX_BOX}>
      <Box display="flex">
        <Typography noWrap variant="caption">{`${readStart(event)} - ${readEnd(event)}`}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography noWrap variant="caption">{readAttendee(event)}</Typography>
      </Box>
      <Paper sx={getSxPaper(time, event, eventCurrent, eventNext)}>
        <Box display="flex">
          <Box sx={getSxLogo(readOrganisation(event))} />
          <Box sx={{ width: '8vw' }}>
            <Typography noWrap component="div" variant="caption" fontWeight="bold">{readTitle(event)}</Typography>
            <Typography noWrap component="div" variant="caption">{readOrganisation(event)}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

CalenderMonthEvent.propTypes = {
  time: PropTypes.instanceOf(Date).isRequired,
  event: PropTypes.shape({
    uid: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    summary: PropTypes.string,
    attendee: PropTypes.shape({
      params: PropTypes.shape({
        CN: PropTypes.string,
      }),
    }),
  }).isRequired,
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({}),
};

CalenderMonthEvent.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
};
