import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function InfoBooking() {
  return (
    <Paper sx={{ p: 0.5, mr: 1 }}>
      <Box display="flex">
        <Box sx={{ p: 0.1 }}>
          <img
            src={`${process.env.PUBLIC_URL}/qr-code.png`}
            alt="https://skedda.rotes.haus"
            style={{ maxWidth: '96px', maxWeight: '96px' }}
          />
        </Box>
        <Box>
          <Typography component="div" variant="caption" sx={{ mt: 2 }}>Um eine Buchung zu erstellen,</Typography>
          <div>
            <Typography variant="caption">bitte unter </Typography>
            <Typography variant="caption" fontWeight={900}>skedda.rotes.haus</Typography>
          </div>
          <Typography component="div" variant="caption">einloggen.</Typography>
        </Box>
      </Box>
    </Paper>
  );
}
