import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: 'rgb(205, 12, 31)',
    },
    secondary: {
      main: 'rgb(20, 227, 75)',
    },
    background: {
      default: '#121212',
      dayPast: 'rgba(47, 47, 47, 0.6)',
      dayFuture: 'rgba(47, 47, 47, 1)',
      eventPrevious: '#c2c2c2',
    },
  },
  typography: {
    h1: {
      fontSize: '3.6rem',
    },
    h2: {
      fontSize: '1rem',
    },
    h3: {
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1.5rem',
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'unset',
          borderSpacing: 1,
          overflow: 'hidden',
          padding: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(60, 60, 60, 1)',
          border: 'unset',
          color: '#ffffff',
          textAlign: 'center',
        },
      },
    },
  },
});
