import React, { useRef, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';
import { ThemeProvider } from '@mui/material/styles';
import 'moment/locale/de';
import 'moment-timezone';

import theme from './themes/default';
import AppLayout from './AppLayout';

moment.locale('de');
moment.tz.setDefault('Europe/Vienna');

const ENVS = {
  PROD: 'PROD',
  TEST: 'TEST',
};
const ENV = ENVS.PROD;
// const curr = {
//   description: 'Spaces: Medienraum',
//   dtstamp: '2022-06-14T13:51:00.000Z',
//   start: '2022-06-15T23:20:00+02:00',
//   end: '2022-06-15T23:21:00+02:00',
//   resources: 'Medienraum',
//   sequence: '0',
//   status: 'CONFIRMED',
//   summary: 'Michael Horvath (SPÖ Burgenland): TEST  (Medienraum)',
//   type: 'VEVENT',
//   uid: '38511018_637903602000000005',
//   attendee: {
//     params: {
//       CN: 'Michael Horvath (SPÖ Burgenland)',
//     },
//   },
//  };

// const count = {
//   description: 'Spaces: Medienraum',
//   dtstamp: '2022-06-07T13:51:00.000Z',
//   start: '2022-06-07T15:30:00.000Z',
//   end: '2022-06-07T18:00:00.000Z',
//   resources: 'Medienraum',
//   sequence: '0',
//   status: 'CONFIRMED',
//   summary: 'Michael Horvath (SPÖ Burgenland): TEST  (Medienraum)',
//   type: 'VEVENT',
//   uid: '38511018_637903602000000005',
//   attendee: {
//     params: {
//       CN: 'Michael Horvath (SPÖ Burgenland)',
//     },
//   },
// };

const app = initializeApp({
  apiKey: ENV === ENVS.PROD ? process.env.REACT_APP_API_KEY_PROD : process.env.REACT_APP_API_KEY_TEST,
  authDomain: ENV === ENVS.PROD ? process.env.REACT_APP_AUTH_DOMAIN_PROD : process.env.REACT_APP_AUTH_DOMAIN_TEST,
  databaseURL: ENV === ENVS.PROD ? process.env.REACT_APP_DATABASE_URL_PROD : process.env.REACT_APP_DATABASE_URL_TEST,
  projectId: ENV === ENVS.PROD ? process.env.REACT_APP_PROJECT_ID_PROD : process.env.REACT_APP_PROJECT_ID_TEST,
  storageBucket: ENV === ENVS.PROD ? process.env.REACT_APP_STORAGE_BUCKET_PROD : process.env.REACT_APP_STORAGE_BUCKET_TEST,
  messagingSenderId: ENV === ENVS.PROD ? process.env.REACT_APP_MESSAGING_SENDER_ID_PROD : process.env.REACT_APP_MESSAGING_SENDER_ID_TEST,
  appId: ENV === ENVS.PROD ? process.env.REACT_APP_APP_ID_PROD : process.env.REACT_APP_APP_ID_TEST,
});
const database = getDatabase(app);
const refEvents = ref(database, 'events');

const calculateWeeks = (day) => {
  const result = [];
  for (let week = -1; week < 4; week += 1) {
    const days = [];
    const startOf = moment(day).startOf('week').add(week, 'w');
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek += 1) {
      days.push({ id: `${week}-${dayOfWeek}`, date: moment(startOf).add(dayOfWeek, 'd') });
    }
    result.push({ id: week, startOf, days });
  }
  return result;
};

const calenderOrder = {
  month: 'week',
  week: 'month',
};

export default function App() {
  const events = useRef({});
  const calender = useRef('month');
  // const calender = useRef('month');
  const day = useRef(new Date());
  const weeks = useRef(calculateWeeks(day.current));
  const eventCurrent = useRef();
  const eventNext = useRef();
  const eventPrevious = useRef();
  const timestamp = useRef(new Date());
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const calculateTime = () => {
      const newTime = new Date();
      if (!moment(newTime).isSame(moment(day.current), 'day')) {
        day.current = newTime;
        weeks.current = calculateWeeks(newTime);
      }
      if (moment(newTime).diff(moment(timestamp.current), 'seconds') >= 60) {
        calender.current = calenderOrder[calender.current];
        timestamp.current = newTime;
      }
      if (events.current) {
        const current = Object.values(events.current)
          .filter((item) => moment(item.start).isBefore(moment(newTime)) && moment(item.end).isAfter(moment(newTime)));
        if (current.length === 0 && !!eventCurrent.current) {
          eventCurrent.current = undefined;
        } else if (!eventCurrent.current || eventCurrent.current.uid !== current[0].uid) {
          [eventCurrent.current] = current;
        }

        const previous = Object.values(events.current)
          .filter((item) => moment(item.end).isBefore(moment(newTime)))
          .sort((a, b) => moment(b.start).diff(moment(a.start)));
        if (previous.length === 0 && !!eventPrevious.current) {
          eventPrevious.current = undefined;
        } else if (!eventPrevious.current || eventPrevious.current.uid !== previous[0].uid) {
          [eventPrevious.current] = previous;
        }

        const next = Object.values(events.current)
          .filter((item) => moment(item.start).isAfter(moment(newTime)))
          .sort((a, b) => moment(a.start).diff(moment(b.start)));
        if (next.length === 0 && !!eventNext.current) {
          eventNext.current = undefined;
        } else if (!eventNext.current || eventNext.current.uid !== next[0].uid) {
          [eventNext.current] = next;
        }
      }
      setTime(newTime);
    };
    const interval = setInterval(calculateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  onValue(refEvents, (snapshot) => {
    events.current = snapshot.val();
    // events.current.test = curr;
    // events.current.test = count;
  });

  return (
    <ThemeProvider theme={theme}>
      <AppLayout
        calender={calender.current}
        eventCurrent={eventCurrent.current}
        eventNext={eventNext.current}
        eventPrevious={eventPrevious.current}
        events={events.current}
        time={time}
        weeks={weeks.current}
      />
    </ThemeProvider>
  );
}
