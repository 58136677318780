export default {
  'GVV Burgenland': {
    path: 'gvv.png',
  },
  'Naturfreunde Burgenland': {
    path: 'naturfreunde.png',
  },
  'PVÖ Burgenland': {
    path: 'pvoe.png',
  },
  'Renner Institut Burgenland': {
    path: 'ri.png',
  },
  'SJ Burgenland': {
    path: 'sj.png',
  },
  'SPÖ Bezirksorganisation Eisenstadt': {
    path: 'spoe_bez_e.png',
  },
  'SPÖ Burgenland': {
    path: 'spoe_bgld.png',
  },
  'SPÖ Frauen Burgenland': {
    path: 'spoe_frauen.png',
  },
  'Volkshilfe Burgenland': {
    path: 'volkshilfe.png',
  },
};
