import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import InfoBooking from './InfoBooking';
import InfoClock from './InfoClock';
import InfoTime from './InfoTime';
import Event from './Event';
import EventDetail from './EventDetail';

export default function Info({
  eventCurrent,
  eventNext,
  eventPrevious,
  time,
}) {
  const sxEventPrevious = {
    backgroundColor: '#c2c2c2',
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ height: '100%', pb: 2 }}>
      <InfoTime time={time} />
      <InfoClock time={time} eventCurrent={eventCurrent} eventNext={eventNext} />
      { eventCurrent && <EventDetail label="Aktuelle Buchung:" event={eventCurrent} />}
      { !eventCurrent && eventNext && <EventDetail label="Nächste Buchung:" color="secondary.main" event={eventNext} />}
      <Box sx={{ flexGrow: 1 }} />
      <InfoBooking />
      { eventCurrent && eventNext && <Event label="Nächste Buchung:" event={eventNext} />}
      { eventPrevious && <Event label="Vorherige Buchung:" event={eventPrevious} sx={sxEventPrevious} />}

    </Box>
  );
}

Info.propTypes = {
  eventCurrent: PropTypes.shape({}),
  eventNext: PropTypes.shape({}),
  eventPrevious: PropTypes.shape({}),
  time: PropTypes.instanceOf(Date).isRequired,
};

Info.defaultProps = {
  eventCurrent: undefined,
  eventNext: undefined,
  eventPrevious: undefined,
};
